@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.SliderContainer {
  width: 100%;
  height: 22em;
}

.slick-slide {
  padding-top: 6em;
  height: 15em;
}

.slide {
  transform: scale(0.8);
  transition: transform 300ms;
  opacity: 0.5;
  z-index: -9;
}
.activeSlide {
  transform: scale(2.5);
  position: relative;
  z-index: 15;
  opacity: 1;
}

.arrow {
  transform: scale(1.3);
}

.slick-prev {
  left: 0px;
}
.slick-next {
  right: 0px;
}

.hover {
  opacity: 0.5;
  transform: scale(1.6);
}

@media screen and (max-width: 1023px) {
  .arrow {
    opacity: 0;
  }
  .SliderContainer {
    height: 20em;
  }
}

@media screen and (max-width: 768px) {
  .SliderContainer {
    height: 22em;
  }
}

@media screen and (max-width: 425px) {
  .SliderContainer {
    height: 18em;
  }
}
